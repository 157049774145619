require("jquery-ui")

$(function() {
	// auto-complétion des villes
	var autocompleteOptions = {
		source: function (request, response) {
		$.ajax({
			url: "../cities/",
			data: { q: request.term },
			dataType: "json",
			success: function(data) {
				response($.map(data, function (city) {
                	if(typeof(city.properties['addr:postcode'])!="undefined") {
                		return {
                			// si plusieurs codes postaux, on ne prends que le premier
                    		label: city.properties.name+" ("+city.properties['addr:postcode'].split(";")[0]+")",
                    		value: city.properties.name+" ("+city.properties['addr:postcode'].split(";")[0]+")"
                    	};
                    }
                    else {
                    	return {
                    		label: city.properties.name,
                    		value: city.properties.name
                    	};
                    }
                }))
			},
			error: function () {
				response([]);
			}
			});
		},
        change: function (event, ui) {
            if(!ui.item){
                // http://api.jqueryui.com/autocomplete/#event-change
                // The item selected from the menu, if any. Otherwise the property is null
                // So clear the item for force selection
                $(this).val("");
            }
        },
		minLength: 2,
		delay: 100
	}
	$('#start_city').autocomplete(autocompleteOptions);
	$('#end_city').autocomplete(autocompleteOptions);

	// gestion des étapes
	var fieldHTML = '<div style="margin-bottom: 5px;"><input type="text" class="form-control form-control-sm middle_cities" required="required" name="middle_cities[]" value=""/></div>';
	$('#add_city').click(function(){
		$('#middle_cities_container').append(fieldHTML);
		$('#middle_cities_container').find('input[type=text]:last').autocomplete(autocompleteOptions);
		$("#remove_city").show();
	});
	$('#remove_city').on('click', function(e){
		$('#middle_cities_container').children().last().remove();
		if ($('#middle_cities_container').children().length == 0) {
			$("#remove_city").removeAttr("style").hide();
		}
	});

	// validation
	var forms = document.getElementsByClassName('needs-validation');
	var validation = Array.prototype.filter.call(forms, function(form) {
		form.addEventListener('submit', function(event) {
			if (form.checkValidity() === false) {
				event.preventDefault();
				event.stopPropagation();
			}
			form.classList.add('was-validated');
		}, false);
	});

	// icônes
	startIcon = L.icon({
		iconUrl: require('images/pin-icon-start.png'),
		iconAnchor: [16, 45]
	});
	endIcon = L.icon({
		iconUrl: require('images/pin-icon-end.png'),
		iconAnchor: [16, 45]
	});
	waterIcon = L.icon({
		iconUrl: require('images/pin-icon-drinking_water.png'),
		iconAnchor: [17, 50]
	});
	restaurantIcon = L.icon({
		iconUrl: require('images/pin-icon-restaurant.png'),
		iconAnchor: [16, 42]
	});
	trainStationIcon = L.icon({
		iconUrl: require('images/pin-icon-trainstation.png'),
		iconAnchor: [17, 49]
	});
	hotelIcon = L.icon({
		iconUrl: require('images/pin-icon-hotel.png'),
		iconAnchor: [16, 42]
	});

	// ajout d'un zoom lors du clic sur une étape
	$("#output").on("click", "tr", function() {
		southEast_ll = JSON.parse($(this).find("th > span#coordinates_southeast").text());
		northWest_ll = JSON.parse($(this).find("th > span#coordinates_northwest").text());
		var bounds = L.latLngBounds(southEast_ll, northWest_ll);
		mymap.fitBounds(bounds);
	});
});